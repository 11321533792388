import { Component } from "react";


class Header extends Component {
    render() { 
        return (
            <header className="header">
                <div className="menu-bar"></div>
                <ul className="dfd-menu pull-left">
                    <li><a className="custom-btn" data-options="{ &quot;easing&quot;: &quot;easeInQuad&quot; }" data-scroll="" href="#demo-section">Demos</a></li>
                    <li><a className="custom-btn" data-options="{ &quot;easing&quot;: &quot;easeInQuad&quot; }" data-scroll="" href="#feature-section">Features</a></li>
                </ul>
                <a className="logo" href="index.html"><img alt="logo" src="assets/images/logo.png" /></a>
                <div className="purchase pull-right"><a href="https://www.templatemonster.com/landing-page-template/niftyland-nft-landing-page-and-portfolio-react-js-template-251078.html" className="default-btn">PURCHASE NOW</a></div>
            </header>
        );
    }
}
 
export default Header;